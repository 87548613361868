import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  name,
  position,
  comment,
  odd = false,
  url
}) => (
  <Grid sm={12} md={6} item
    className={clsx(
      styles.customerWrapper,
      odd ? styles.customerContainerRight : styles.customerContainerLeft
    )}>
    <div className={styles.customerContainer}>
      <Typography variant='h4' align='left' weight='regular'>
        {name}
      </Typography>
      <Typography variant='subtitle2' align='left' weight='light' color='dark'
        className={styles.positionContainer}>
        {position}
      </Typography>
      <Typography variant='subtitle1' align='left' weight='light' color='dark'>
        {comment}
      </Typography>
      {url
      ? <Typography variant='subtitle1' align='left' weight='light'
          color='dark'
          className={styles.readMoreContainer}>
          {url.pretext} <a className={styles.readMoreAnchor} href={url.link}>{url.anchorText}</a>
        </Typography>
      : null}
    </div>
  </Grid>
);