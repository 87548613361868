import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Third from './Third';
import NarrowIcon from './NarrowIcon';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        main: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-Main.jpg"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow0: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-Narrow-1.png"}
        }) {
          fluid(maxWidth: 143, maxHeight: 143, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow1: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-Narrow-2.png"}
        }) {
          fluid(maxWidth: 143, maxHeight: 143, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow2: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-Narrow-3.png"}
        }) {
          fluid(maxWidth: 143, maxHeight: 143, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        third1: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-1.png"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        third2: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-2.png"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        third3: imageSharp(fluid: {
          originalName: {eq: "Why-Fleet-3.png"}
        }) {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({
      main,
      narrow0,
      narrow1,
      narrow2,
      third1,
      third2,
      third3
    }) => (
      <SectionWrapper className={styles.container}>
        <Title containerClass={styles.titleContainer}
          variant='h3'>
          {i18n('Make repair and maintenance easy')}
        </Title>
        <Subtitle containerClass={styles.subtitleContainer}
          color='dark'>
          {i18n('Why complicate the administration of your vehicles when the professionals can help you in every step of the way?')}
        </Subtitle>
        <Grid container
          alignItems='center'>
          <Grid item xs={12} md={4} xl={4} className={clsx(
              styles.gridItem,
              styles.gridItemLeftEdge
            )}>
            <Typography color='dark' variant='h4' align='left'
              className={styles.boxedDescriptionTitle}>
              {i18n('Be contacted when key events like maintenances, inspections (syn), change of tires and others should be performed.')}
            </Typography>
            <Typography align='left' variant='h5' weight='light' color='dark'
              className={styles.boxedDescription}>
              {i18n('Your employees should not be held accountable for forgetting to share that the engine lamp is on.')}
            </Typography>
          </Grid>
          <Grid container className={styles.narrowIconsContainer}>
            <NarrowIcon image={narrow0}
              text={i18n('Maintenance')}/>
            <NarrowIcon image={narrow1}
              text={i18n('Inspections')}/>
            <NarrowIcon image={narrow2}
              text={i18n('Tire changes')}/>
          </Grid>
          <Grid item xs={12} md={8} xl={8} className={clsx(
              styles.gridItem,
              styles.gridItemRightEdge
            )}>
            <div className={clsx(
                styles.imageContainer,
                styles.mainImage
              )}>
              <Img imgStyle={{ objectFit: 'contain', borderRadius: '16px' }}
                objectFit='contain'
                className={styles.image}
                fluid={main.fluid}
                alt="Hero Picture"/>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Third image={third1}
            text={i18n('Safer vehicles to drive')}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemLeftEdge
            )}/>
          <Third image={third2}
            text={i18n('Communicate with your preferred garage at the right time.')}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemMiddle
            )}/>
          <Third image={third3}
            text={i18n('Receive help in breakdowns')}
            containerClass={clsx(
              styles.gridItem,
              styles.gridItemRightEdge
            )}/>
        </Grid>
      </SectionWrapper>
    )}/>
);