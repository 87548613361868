import React from "react";
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/fleet';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Advantages from './Advantages';
import Motivation from './Motivation';
import Customers from './Customers';
import CallToAction from '../../CallToAction';
import CaseStudies from './CaseStudies';
import { locale } from '../../../locale';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.fleet.why}
    subheader={Subheader}
    url='_url:fleet-management-why'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Advantages/>
    <Motivation/>
    <Customers/>
    { locale === 'da'
      ? <CaseStudies/>
      : null
    }
    <CallToAction/>
  </Layout>
);