export default [{
  name: 'Simon Jæger Jensen',
  position: 'Direktør hos Bytømreren, bruger af au2cloud Fleet',
  comment: '"Man skal hele tiden bevare overblikket over standen på den enkelte bil i flåden - og det er svært. Hvornår skal den til service, hvad er km-stand, er der fejl på bilen?"',
  url: {
    pretext: 'Læs mere i',
    anchorText: 'Børsen',
    link: 'https://borsen.dk/sponsoreret/sim-kort-i-motoren-og-data-i-skyen-giver-effektivt-overblik-over-bilflaaden'
  }
}, {
  name: 'Magne Jakobsen',
  position: 'Direktør hos Intopit, bruger af au2cloud Fleet',
  comment: '"Der er værdi i at have alle oplysninger på bilen liggende, så man kan træffe den beslutning, der er bedst for totaløkonomien."',
  url: {
    pretext: 'Læs mere i',
    anchorText: 'Børsen',
    link: 'https://borsen.dk/sponsoreret/tracking-systemer-til-motorer-sporer-daarlig-braendstofokonomi-og-sikrer-vedligehold'
  }
}, {
  name: 'Thomas Maegaard-Jacobsen',
  position: 'Kommunikationsmedarbejder hos Høgh & Sønberg, bruger af au2cloud Fleet',
  comment: '"Der er selvfølgelig en håndværker, der kører i bilen og mærker efter, om den kører, som den skal. Men det ville være umuligt at tjekke alle bilerne hver dag, det har vi simpelthen for travlt til."',
  url: {
    pretext: 'Læs mere i',
    anchorText: 'BT',
    link: 'https://www.bt.dk/annonce/nu-taler-bilerne-selv-med-vaerkstedet'
  }
}, {
  name: 'Rene Lang',
  position: 'Kædechef for AutoPartner',
  comment: '"Hvis en tømrer for eksempel er på en opgave og opdager, at han mangler søm, så behøver han ikke spilde sin tid på at køre 25 kilometer til den nærmeste tømmerhandel. For med au2cloud kan hans chef se, at den nærmeste kollega måske kun er fem kilometer væk, og så kan tømreren jo bare køre derover og låne nogle søm."',
  url: {
    pretext: 'Læs mere i',
    anchorText: 'BT',
    link: 'https://www.bt.dk/annonce/nu-taler-bilerne-selv-med-vaerkstedet'
  }
}];