import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Title from '../../../../components/Title';
import SectionWrapper from '../../../../components/SectionWrapper';
import Advantage from './Advantage';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper id='advantages' className={styles.container}>
    <Title variant='h3'>
      {i18n('Advantages')}
    </Title>
    <Grid container>
      <Advantage title={i18n('Optimize your fleet')}
        description={i18n('No matter the size of your fleet, you can use information that you never had before. It is time to utilize data that matters to increase cost savings and driver retention rates.')}/>
      <Advantage title={i18n('Increase productivity')} right
        description={i18n('Know where your vehicles are, whether they are moving or parked. Send over the closest driver to perform a job in the area. Access historical data about the utilization of the fleet and individual vehicles.')}/>
      <Advantage title={i18n('Receive more professional service')}
        description={i18n('Better management of your assets through remote supervision and coordination of key events. 8 out of 10 employees forget to notify a manager about key car-related events (e.g. service/engine light on).')}/>
      <Advantage title={i18n('Increase the safety of your drivers')} right
        description={i18n('Get peace of mind by knowing that your drivers and vehicles are safe to drive. Have a guardian angel (your mechanic) that can translate any issues that occur in simple words')}/>
      <Advantage title={i18n('Prevent unauthorized mobility')}
        description={i18n('The service is definitely something for you if your goal is to reduce insurance and maintenance costs or to receive timely alerts and information about unauthorized usage of the vehicles.')}/>
      <Advantage title={i18n('Automate data entry')} right
        description={i18n('Eliminate manual mileage tracking to save time and increase the productivity of your drivers. Each driver or fleet manager can categorize the trips and add relevant notes.')}/>
    </Grid>    
  </SectionWrapper>
);