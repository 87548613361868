import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import BlogItem from './BlogItem';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`query {
      blog1: imageSharp (fluid: {
        originalName: { eq: "Fleet-Casestudy-Blog1.png" }
      }) {
        fluid(maxWidth: 731, maxHeight: 328, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blank: imageSharp (fluid: {
        originalName: { eq: "Fleet-Casestydy-Blank.jpg" }
      }) {
        fluid(maxWidth: 688, maxHeight: 715, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }`}
    render={({ blog1, blank }) => (
      <Grid container justifyContent='center' alignContent='center'
        className={styles.container}>
        <Grid item container xs={12} sm={12} md={12} lg={12}>
          <Typography
              variant='h2'
              color='dark'
              weight='bold'
              className={styles.componentTitle}>
                {i18n('Casestudie')}
          </Typography>
        </Grid>
        
        <BlogItem
          image={blog1}
          title={i18n('Byggefirma - DownTown ApS')}
          subtitle={i18n('En effektiv og simpel flådestyringsløsning som skærper medarbejdernes parathed')}
          link={'https://da.obiplus.com/blog/flaadestyringsloesning-for-medarbejdere'}
        />

        <Grid item container xs={12} sm={12} md={12} lg={6} className={styles.blogContainer}>
          <Img imgStyle={{ objectFit: 'cover', width: '100%', height: '100%' }}
            objectFit='cover'
            className={styles.imageBlank}
            fluid={blank.fluid}/>
        </Grid>

      </Grid>
    )
  }/>
);